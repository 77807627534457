%mb-40 {
    margin-bottom: 2.5rem;
}

@mixin flex($dir, $align, $justify) {
    display: flex;
    align-items: $align;
    justify-content: $justify;
    flex-direction: $dir;
}

@mixin font_size($size, $type, $weight) {
    font-style: normal;
    font-weight: normal;
    @if $type == "rem" {
        font-size: $size + $type;
    } @else {
        $type: rem;
        font-size: ($size / 16) + $type;
    }
    // line-height: ($size * 1.1) + $type;
}

@mixin background-gradient($base-color, $shine-color) {
    background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
    background-size: 600px;
}

@function rem($value) {
    $type: rem;
    @return $value / 16 + $type;
}

@mixin grid($width, $gap) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax($width, 1fr)) 280px;
    grid-gap: $gap;
}

@mixin button($color, $bg-color, $padding, $radius: var(--radius)) {
    color: $color;
    background-color: $bg-color;
    padding: $padding !important;
    @extend %clear-outline;
    border-radius: $radius;
}

.grid {
    &__col-3 {
        @include grid(rem(350), rem(50));
    }

    &-item {
        &__create-task {
            color: var(--color-gray);
            
            p {
                margin-bottom: rem(10) !important;
            }
        }
    }
}
