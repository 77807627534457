@media (prefers-color-scheme: dark) {
    :root {
        --main-bg: #131419;
        --color-purple: #4447e2;
        --color-dark-gray: #2d2d3a;
        --color-3: #231f20;
        --color-bg-indigo: #1c1c24;
        --color-greenish: #0f1d2d;
        --color-modal-bg: #0f1d2d7e;
        --color-input: #2d2d3a;
        --color-white: #ffffff;
        --color-gray: #5f5f6e;
        --color-solid-gray: #5f5f6f;
        --color-smoke-gray: #8e8e96;
        --color-ghost-gray: #a6adb1;
        --base-skeleton-color: rgba(82, 82, 82, 0.205);
        --color-8: #262631;
        --base-skeleton-shine: rgba(110, 110, 110, 0.363);
        --color-9: #4b4b62;
        --color-light-gray: #343444;
        --transition: all 0.2s linear;
        --radius: 15px;
        --color-red: #e24444;
        --color-bg-switcher: #dde1e8;
    }
}
