.base-container {
    max-width: calc(100vw - 100px * 2);
    width: 100%;
    margin: 0 auto;
}

.text-right {
    text-align: right;
}

.main-wrapper {
    margin: 2.5rem 0;

    &-title {
        @include font_size(1.625, rem, 500);
        color: var(--color-white);
    }
}

.toggle-control {
    position: relative;
    padding-left: $toggle-width;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    margin-top: -20px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    input:checked ~ .control {
        background-color: $toggle-background-color-on;

        &:after {
            left: $toggle-width - $toggle-control-size - $toggle-gutter;
        }
    }

    .control {
        position: absolute;
        top: 0;
        left: 0;
        height: $toggle-height;
        width: $toggle-width;
        border-radius: $toggle-radius;
        background-color: $toggle-background-color-off;
        transition: background-color $toggle-control-speed $toggle-control-ease;

        &:after {
            content: "";
            position: absolute;
            left: $toggle-gutter;
            top: $toggle-gutter;
            width: $toggle-control-size;
            height: $toggle-control-size;
            border-radius: $toggle-radius;
            background: $toggle-control-color;
            transition: left $toggle-control-speed $toggle-control-ease;
        }
    }
}

.dropdown-enter {
    opacity: 0;
    transform: scale(0.9);
}
.dropdown-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.dropdown-exit {
    opacity: 1;
}

.dropdown-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.pages-enter {
    opacity: 0;
    transform: translateX(-100vw);
}

.pages-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.3s linear;
}

.pages-exit {
    opacity: 1;
}

.pages-exit-active {
    opacity: 0;
    transform: translateX(-100vw);
    transition: all 0.3s linear;
}

.skeleton {
    &-top {
        display: flex;
    }

    &-avatar {
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 50%;
        background-color: var(--color-dark-gray);
        @include background-gradient(var(--base-skeleton-color), var(--base-skeleton-shine));
        animation: shine-lines 1s infinite linear;
    }

    &-info {
        margin-left: 0.625rem;
        width: 7rem;

        &-inner {
            background-color: var(--color-dark-gray);
            border-radius: var(--radius);
            @include background-gradient(var(--base-skeleton-color), var(--base-skeleton-shine));
            animation: shine-lines 1s infinite linear;

            &:first-child {
                height: 1.125rem;
                margin-bottom: 6px;
            }

            &:last-child {
                height: 0.9rem;
            }
        }
    }

    &-img {
        width: 100%;
        height: 11.25rem;
        @include flex(row, center, center);
        background-color: var(--color-dark-gray);
        margin: 1.25rem 0;
        border-radius: var(--radius);
        @include background-gradient(var(--base-skeleton-color), var(--base-skeleton-shine));
        animation: shine-lines 1s infinite linear;
    }

    &-btn {
        height: 3.125rem;
        border-radius: var(--radius);
        background-color: var(--color-dark-gray);
        @include flex(row, center, center);
        @include background-gradient(var(--base-skeleton-color), var(--base-skeleton-shine));
        animation: shine-lines 1s infinite linear;

        &-inner {
            width: 6.25rem;
            height: 1.25rem;
            background-color: var(--color-light-gray);
            border-radius: var(--radius);
        }
    }
}

@keyframes shine-lines {
    0% {
        background-position: -100%;
    }

    40%,
    100% {
        background-position: 100%;
    }
}

.fixed-button {
    position: fixed;
    right: 1.875rem;
    bottom: 8.75rem;
    z-index: 1;
    background-color: var(--color-bg-indigo);
    border-radius: 50%;
    padding: 7px;
    overflow: hidden;

    button {
        background: transparent;
        border: none;
        outline: none;
    }
}

.field-wrapper {
    margin-bottom: 1.25rem;

    .form-label {
        @include font_size(14, px, 400);
        color: var(--color-gray);
    }

    .form-input {
        background-color: var(--color-input);
        border: none;
        padding: 0 15px;
        height: 50px;
        color: var(--color-white);
        @include font_size(1, rem, 500);
        border-radius: var(--radius);

        &[disabled] {
            background-color: var(--color-input);
            color: var(--color-white) !important;
            opacity: 0.7;
        }

        &:focus {
            filter: saturate(1.2);
            background-color: var(--color-input) !important;
            color: var(--color-white);
        }
    }
}

.password-section {
    @extend %mb-40;

    &-title {
        @include font_size(14, px, 500);
        margin-bottom: 10px !important;
        color: var(--color-gray);
    }
}

.mb-40 {
    margin-bottom: 2.5rem;
}

.widgets {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    width: 100%;
    margin-top: 1.4rem !important;
    grid-gap: 12px;

    .widget {
        background-color: #4447e2;
        color: #fff;
        border-radius: var(--radius);
        @include font_size(14, px, 500);
        padding: 10px 20px;
        text-align: center;
    }
}

.pagination {
    display: flex;
    align-items: center;
    width: 100% !important;
    margin: rem(20) 0;

    &-wrapper {
        @include flex(row, center, stretch);
        flex-wrap: wrap;
    }
    &-item {
        color: var(--color-white);
        margin: 0 rem(8);
        border-radius: rem(12);
        background-color: transparent;
        border: 2px solid var(--color-purple);
        cursor: pointer;
        transition: var(--transition);
        min-width: 45px;
        text-align: center;

        a {
            padding: rem(10) rem(12);
            display: block;
            width: 100%;
            height: 100%;
        }

        &:hover,
        &.active {
            background-color: var(--color-purple);
        }

        &.truncate {
            pointer-events: none;
        }

        &.disabled {
            display: none;
        }

        // &.truncate::before {
        //     content: '...';
        // }

        &.hide {
            display: none;
        }
    }
}

/* Custom checkbox for task creation */

.custom-checkbox {
    border: none;
    background-color: var(--color-bg-indigo);
    color: var(--color-white);
    @include flex(row, center, stretch);
    padding: rem(10);
    border-radius: var(--radius);
    cursor: pointer;
    user-select: none;
    transition: var(--transition);

    &.active {
        background-color: var(--color-greenish);
    }

    &-logo {
        margin-right: rem(10);
        width: rem(40);
        border-radius: rem(12);

        img {
            width: 100%;
            height: 100%;
        }
    }

    &-title {
        @include font_size(16, px, 500);
    }

    .checkmark {
        margin-left: auto;
        border-radius: 50%;
        width: rem(20);
        height: rem(20);
        border: 2px solid var(--color-dark-gray);
        @include font_size(row, center, center);

        img {
            width: 100%;
            height: 100%;
            margin-top: -12px;
        }
    }
}

//modal

.modal-layout {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
    background-color: var(--color-modal-bg);
    @include flex(row, center, center);

    .modal-wrapper {
        @include flex(column, stretch, stretch);
        padding: rem(30);
        border-radius: var(--radius);
        background-color: var(--color-greenish);
        min-width: rem(520);

        .main-wrapper-title {
            margin-bottom: rem(26);
            font-size: 18px;
        }
        .btn {
            border-radius: var(--radius);

            &-modal-remove {
                background-color: var(--color-red);
                color: var(--color-white);
            }

            &-modal-exit {
                margin-right: rem(10);
                color: var(--color-white);
                background-color: rgba(2, 66, 66, 0.247);
                border: 2px solid rgba(2, 66, 66, 0.589);
            }
        }
    }
}

//progressbar

.progressbar {
    height: 6px;
    border-radius: 5px;
    background-color: var(--color-dark-gray);
    margin-top: 5px;

    &-inner {
        background-color: var(--color-purple);
        border-radius: 5px;
        height: 100%;
        transition: var(--transition);
    }

    &-wrapper {
        padding: 0 rem(30) 0 rem(10);
        @include flex(column, stretch, stretch);
    }

    &-cell {
        width: rem(350);
    }

    &-points {
        @include flex(row, center, space-between);
        @include font_size(14, px, 400);
    }
}
