@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");
@import "colors";
@import "variables";
@import "helpers";
@import "constants";
@import "shared";


@font-face {
    font-family: "SF Pro Display";
    src: url("../fonts/FontsFree-Net-SFProDisplay-BoldItalic.ttf") format("ttf"),
        url("../fonts/FontsFree-Net-SFProDisplay-MediumItalic.ttf") format("ttf"),
        url("../fonts/FontsFree-Net-SFProDisplay-Regular.ttf") format("ttf"),
        url("../fonts/FontsFree-Net-SFProDisplay-SemiboldItalic.ttf") format("ttf");
}

