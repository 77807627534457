@import "./style";

html,
body {
  overflow-x: hidden;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  letter-spacing: 0.4px;

  &:focus {
    box-shadow: none !important;
    outline: none;
  }
}

a {
  text-decoration: none !important;
}

p {
  margin-bottom: 0 !important;
}

hr {
  background-color: var(--color-white) !important;
  margin: 0.3rem 0 !important;
}

body {
  margin: 0;
  // font-family: "SR Pro Display", sans-serif !important;
  font-family: "Noto Sans JP", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg) !important;
  font-size: 16px;
}

.navbar-wrapper {
  background: var(--color-bg-indigo);
  border-bottom: 2px solid var(--color-dark-gray);

  .main-nav {
    padding: 1.3rem 0 0.8rem 0;

    .navbar-brand {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 0;
        color: var(--color-white);
      }
    }

    .center-menu {
      @include flex(column, stretch, center);

      .nav {
        margin: 0 auto;
      }

      .nav-item {
        margin: 0 1rem;

        .nav-link {
          width: 100%;
          @include font_size(1.125, rem, 400);
          @include flex(row, center, stretch);
          color: var(--color-gray);
          transition: var(--transition);

          &.active,
          &:hover {
            filter: brightness(0) invert(1);
          }

          img {
            margin-right: 0.5rem;
          }
        }
      }
    }

    .user-info-nav {
      @include flex(row, center, flex-end);

      .coin-info {
        @include flex(row, center, space-between);
        background: var(--color-dark-gray);
        border-radius: var(--radius);
        padding: 0.3rem 1.875rem 0.3rem 0.3rem;
        min-width: 7.2rem;
        color: var(--color-white);
        margin-right: 0.625rem;

        .increase-btn-nav {
          background-color: var(--color-purple);
          width: 30px;
          height: 30px;
          display: inline-block;
          @include flex(row, center, center);
          border-radius: 10px;
          margin-right: 1.25rem;
        }

        .coin-balance {
          @include flex(row, center, stretch);

          img {
            margin-right: 5px;
          }
        }
      }

      .user-dropdown {
        position: relative;
        padding-right: 0;

        &-btn {
          background-color: transparent;
          border: none;

          img {
            &:first-child {
              border-radius: 50%;
              width: 40px;
              height: 40px;
              margin-right: 0.625rem;
            }

            &-caret {
              transition: var(--transition);
            }
          }
          .rotated {
            transform: rotateZ(180deg);
          }
        }

        &-wrapper {
          position: absolute;
          top: 100%;
          right: 1.2rem;
          // display: none;
          margin-top: 0.5rem;
          min-width: 15.6rem;
          z-index: 1;
          background: var(--color-bg-indigo);
          padding: 0.7rem;
          border: 2px solid var(--color-dark-gray);
          border-radius: var(--radius);
          cursor: auto;

          .list-group-item {
            background-color: transparent;
            color: var(--color-white);
            text-align: left;
            @include font_size(1, rem, 500);
            border: none;

            &.log-out {
              color: var(--color-red);
            }

            img {
              margin-right: 0.625rem;
            }

            * {
              text-decoration: none;
              color: inherit;
            }
          }

          .theme-switcher-btn {
            @include flex(row, center, space-between);
            width: 100%;
            background: transparent;
            border: none;
          }
        }
      }
    }
  }
}

.main-footer {
  color: var(--color-solid-gray);
  padding: 2rem 0;
  border-top: 2px solid #262631;

  .social-links {
    a {
      margin-right: 1.3rem;
    }
  }
}

.task-list {
  margin: 1.25rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  grid-gap: 1.25rem;

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .create-task {
      background-color: var(--color-input);
      border-radius: var(--radius);
      padding: 15px 20px;
      border: none;
      outline: none;
      text-decoration: none !important;
      @include font_size(16, px, 500);
      color: var(--color-white);
    }
  }

  &-card {
    border-radius: var(--radius);
    background-color: var(--color-bg-indigo);
    padding: 1.25rem;

    &-top {
      display: flex;
      color: var(--color-white);
    }

    .avatar {
      border-radius: 50%;
      width: 3.125rem;
      height: 3.125rem;
      margin-right: 0.625rem;
      position: relative;
    }

    .task-owner-name {
      @include font_size(1, rem, 500);
    }

    .task-type {
      @include font_size(0.875, rem, 400);
      color: var(--color-solid-gray);

      &-img {
        position: absolute;
        bottom: 0;
        right: -4px;
        background: var(--color-bg-indigo);
        width: 16px;
        height: 16px;
        @include flex(row, center, center);
        border-radius: 50%;
        padding: 3px;

        img {
          object-fit: contain;
        }
      }
    }

    &-dropdown {
      margin-left: auto;
    }

    &-img {
      margin: 1.25rem 0;
      width: 100%;

      img {
        width: 100%;
        border-radius: var(--radius);
      }
    }
    .action-button {
      border-radius: var(--radius);
      text-decoration: none;
      border: 2px solid var(--color-purple);
      padding: 1rem 2rem;
      text-align: center;
      display: block;
      color: var(--color-white);
      @include font_size(1, rem, 500);
      transition: var(--transition);

      &:hover {
        background-color: var(--color-purple);
      }
    }
  }
}

.news-banner {
  margin: 4rem 0 6.25rem 0;
  border-radius: var(--radius);
  background-color: var(--color-bg-indigo);

  &.skeleton {
    .news-banner-inner {
      align-items: stretch;
    }
    .news-banner-img {
      @include background-gradient(var(--base-skeleton-color), var(--base-skeleton-shine));
      animation: shine-lines 1s infinite linear;
      width: calc(100% - 40vw);
      height: 300px;
      border-radius: var(--radius);
    }
    .news-banner-content-title {
      @include background-gradient(var(--base-skeleton-color), var(--base-skeleton-shine));
      animation: shine-lines 1s infinite linear;
      width: 100%;
      height: 30px;
      margin-top: 30px !important;
      border-radius: var(--radius);

      &-2 {
        width: 40%;
      }
    }
    .news-banner-content-info {
      @include background-gradient(var(--base-skeleton-color), var(--base-skeleton-shine));
      animation: shine-lines 1s infinite linear;
      width: 100%;
      height: 30px;
      margin-top: 30px !important;
      border-radius: var(--radius);
    }
    .news-banner-content-date {
      @include background-gradient(var(--base-skeleton-color), var(--base-skeleton-shine));
      animation: shine-lines 1s infinite linear;
      width: 30%;
      height: 20px;
      margin-top: 30px !important;
      border-radius: var(--radius);
    }
  }

  &-inner {
    padding: 1.25rem;
    display: flex;
    align-items: center;
  }

  &-img {
    flex: 0.4;
    border-radius: var(--radius);
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  &-content {
    flex: 0.6;
    color: var(--color-white);
    padding-left: 2.5rem;
    text-decoration: none !important;
    display: flex;
    flex-direction: column;

    &-title {
      @include font_size(2.125, rem, 500);
      padding-bottom: 1.25rem;
    }

    &-info {
      @include font_size(1.125, rem, 400);
      padding-bottom: 2.5rem;
      color: var(--color-white);
    }

    &-date {
      @include font_size(0.875, rem, 400);
      color: var(--color-ghost-gray);
    }
  }
}

.news-cards {
  margin-top: 2.2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-gap: 1.56rem;

  .news-card {
    padding: 1.25rem;
    background-color: var(--color-bg-indigo);
    border-radius: var(--radius);
    color: var(--color-white);

    &.skeleton {
      .news-card-img {
        width: 100%;
        height: 200px;
        @include background-gradient(var(--base-skeleton-color), var(--base-skeleton-shine));
        animation: shine-lines 1s infinite linear;
      }
      .news-card-info {
        width: 90%;
        height: 30px;
        @include background-gradient(var(--base-skeleton-color), var(--base-skeleton-shine));
        animation: shine-lines 1s infinite linear;
      }
      .news-card-date {
        width: 40%;
        height: 20px;
        @include background-gradient(var(--base-skeleton-color), var(--base-skeleton-shine));
        animation: shine-lines 1s infinite linear;
      }
    }

    &-img {
      width: 100%;
      border-radius: var(--radius);
      overflow: hidden;
      margin-bottom: 1rem;

      img {
        width: 100%;
        height: 220px;
      }
    }

    &-info {
      padding: 0 5px;
      @include font_size(1.25, rem, 500);
    }

    &-date {
      margin-top: 0.625rem;
      padding: 0 5px;
      @include font_size(1, rem, 400);
      color: var(--color-ghost-gray);
    }
  }
}

.profile-wrapper {
  margin: 2.5rem 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;

  &-avatar {
    border-radius: var(--radius);
    padding: 1.25rem;
    background-color: var(--color-bg-indigo);
    margin-bottom: 3.125rem;

    &-img {
      width: 100%;
      border-radius: var(--radius);
      margin-bottom: 1.25rem;

      img {
        width: 100%;
        border-radius: var(--radius);
      }
    }
  }

  .upload-btn {
    border-radius: var(--radius);
    border: 2px solid var(--color-dark-gray);
    width: 100%;
    background-color: transparent;
    padding: 15px;
    color: var(--color-white);
    @include font_size(1, rem, 500);
    transition: var(--transition);

    &:hover {
      background: var(--color-purple);
      border: 2px solid var(--color-purple);
    }
  }

  &-left {
    grid-column: 1 / 4;
    padding-right: 40px;
  }

  &-center {
    grid-column: 4 / 10;
    margin: 0 5rem 0 2.5rem;

    
  }

  &-right {
    grid-column: 10 / 13;

    .personal-statistic {
      &-title {
        @include font_size(14, px, 400);
        color: var(--color-gray);
        margin-bottom: 10px !important;
      }

      &-card {
        background-color: var(--color-bg-indigo);
        margin-bottom: 1.25rem;
        border-radius: var(--radius);
        padding: 15px;
        @include flex(row, center, stretch);

        &-icon {
          width: 50px;
          height: 50px;
          border-radius: var(--radius);
          margin-right: 1.25rem;

          img {
            width: 100%;
            border-radius: inherit;
          }
        }
      }
      &-info {
        &_count {
          color: var(--color-white);
          @include font_size(24, px, 500);
        }

        &_type {
          color: var(--color-gray);
          @include font_size(16, px, 400);
        }
      }
    }
  }
}

.social-media {
  &-title {
    @include font_size(14, px, 500);
    color: var(--color-gray);
    margin-bottom: 10px !important;
  }

  &-card {
    border-radius: var(--radius);
    background-color: var(--color-bg-indigo);
    padding: 15px;
    @include flex(row, center, stretch);
    margin-bottom: 1.25rem;
  }

  &-icon {
    width: 40px;
    height: 40px;
    border-radius: var(--radius);
    overflow: hidden;
    margin-right: 10px;

    img {
      width: 100%;
    }
  }

  &-name {
    color: var(--color-white);
    @include font_size(1, rem, 500);
  }

  &-btn {
    margin-left: auto;
    border-radius: 50%;
    background-color: var(--color-purple);
    width: 20px;
    height: 20px;
    overflow: hidden;
    border: none;
    outline: none;
    color: var(--color-white);
    cursor: pointer;

    img {
      object-fit: contain;
      margin-top: -8px;
    }
  }
}

.news-detail {
  .open-remote {
    padding: 15px 40px;
    text-align: center;
    color: var(--color-white);
    background-color: transparent;
    border: 2px solid var(--color-purple);
    margin-top: 8px !important;
    border-radius: var(--radius);
    width: max-content;
  }

  .byline {
    border-radius: var(--radius);
    padding: 8px 30px;
    background-color: #4447e2;
    color: var(--color-white);
    width: max-content;
    margin-bottom: 18px;
  }

  &-bottom {
    width: 100%;

    .source-date {
      display: flex;
      align-items: center;
      color: #fff;
    }
  }
}

.create-task-section {
  margin: rem(35) 0;
}

.create-task-wrapper {
  margin-bottom: rem(40);

  .checkboxes {
    @include grid(rem(290), rem(20));
  }
}

.create-task-label {
  @include font_size(16, px, 500);
  color: var(--color-gray);
  margin-bottom: rem(10) !important;
}


//history page

.history-wrapper {
  margin: rem(30) 0 rem(20) 0;  

  .show-more {
    @include button(var(--color-dark-gray), transparent, rem(10) rem(20));
    @include font_size(16, px, 500);
    border: 2px solid;
    transition: var(--transition);

    &:hover {
      color: var(--color-white);
    }
  }

  .history-table {
    overflow: auto;
    
    .table {
      min-width: rem(1100);
      border-collapse: separate;
      border-spacing: 0 rem(10);

      th {
        color: var(--color-gray);
        @include font_size(14, px, 500);
      }
    }

    &-row {
      td {
        padding: rem(15) 0;
        background-color: var(--color-bg-indigo);
        vertical-align: middle;
        color: var(--color-white);

        &:first-child {
          border-top-left-radius: var(--radius);
          border-bottom-left-radius: var(--radius);
          padding-left: rem(15);
        }

        &:last-child {
          border-top-right-radius: var(--radius);
          border-bottom-right-radius: var(--radius);
          padding-right: rem(15);
        }
      }
    }

    &-cell {
      .__flex {
        @include flex(row, center, stretch);

        img {
          margin-right: 8px;
          width: rem(40);
        }
      }
    }
  }
}